













import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'

@Component
export default class MeetingCardTooltip extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) meeting!: Meeting

  get description () {
    if (this.meeting.meetingRecipientStatus !== 'MEETING_RECIPIENT_STATUS_MINE') {
      return this.$t('meetings.card.busy').toString()
    }
    return this.meeting.displayName
  }

  get interval () {
    const start = new Date(this.meeting.startAt)
    const end = new Date(start.getTime() + this.meeting.duration * 60000)
    const startInterval = start.toLocaleString(this.$i18n.locale, {
      minute: '2-digit',
      hour: '2-digit',
    })
    const endInterval = end.toLocaleString(this.$i18n.locale, {
      minute: '2-digit',
      hour: '2-digit',
    })
    return `${startInterval} — ${endInterval}`
  }
}
